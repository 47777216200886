import React from "react"

import "./index.scss"

const SubHeading = ({ subHeading }) => (
  <div className="row">
    <div className="col-12 text-center sub-heading">
      <h4>{subHeading}</h4>
    </div>
  </div>
)

export default SubHeading
